import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout/Layout';
import FeatureCard from '../components/Card';
import SplitSection from '../components/SplitSection';
import Callout from '../components/Callout';
import CalloutSimple from '../components/CalloutSimple';
import Seo from '../components/Seo';
import ArrowDown from '../components/ArrowDown';

import integrations from '../assets/drawings/integrations.png';

import datascreenshot from '../assets/screenshots/datascreenshot.png';
import dependenciesscreenshot from '../assets/screenshots/dependenciesscreenshot.png';
import containers from '../assets/screenshots/containers.png';
import dashboard from '../assets/screenshots/dashboard.png';
import dependencies from '../assets/screenshots/dependencies.png';



import demo from '../assets/demo.webm';

export default () => {
  const title = 'Production-like environments, on-demand.';
  const description =
    'Strio creates temporary and easy to set-up environments with anonymized production-like data.';
  const url = 'https://strio.app';

  const images = useStaticQuery(graphql`
    query HomeQuery {
      tools: allFile(filter: { relativeDirectory: { eq: "images/tools" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 48) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <section id="hero-small" className="relative pa-0 bg-lownight overflow-hidden">
        <div className="background" />

        <div className="absolute w-full flex flex-row justify-center bottom-0">
          <AnchorLink
            href="#features"
            className="h-16 z-1 mb-4 lg:mb-6 rounded-full hover:bg-gray-300 transition duration-300 ease-in-out"
          >
          </AnchorLink>
        </div>

        <div className="flex items-center min-h-full relative">
          <div className="container pt-12 pb-16 px-4">
            <div className="lg:pr-8">
              <div className="text-center text-white">
                <h1 className="mt-10 text-2xl md:text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                  Features
                </h1>
                <div className="flex justify-center">
                  <p className="font-light leading-snug text-base md:text-xl xl:text-2xl mt-6 tracking-tight w-2/3 content-center">
                  Strio generates fully-provisioned environments that include containers, data and dependencies, 
                  opening endless possibilities to boost your development workflow.     
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    

      <section>
        <h3 className="text-2xl md:text-4xl font-extrabold leading-snug text-center px-8 pt-12 tracking-tight text-lownight font-display">
          Configure and deploy environments in minutes
        </h3>
        <SplitSection
        reverseOrder
          primarySlot={
            <img
              src={containers}
              alt="A screenshot of containers configuration"
              className="mx-auto h-auto w-full"
              />
          }
          secondarySlot={
            <div className="sm:px-12 lg:pr-32 lg:pl-6 xl:pr-12 xl:pr-6">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
                Manage your containers
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Configure them manually or let Strio get 
              their configuration automatically from your configuration files, such as Helm charts of Kustomize.
              </div>
            </div>
          }
        />
        <SplitSection
          reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Deploy any kind of dependency
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              If your project requires a specific element such as a database, cache or storage to function, Strio lets you specify what you need, either manually or through a 
              Terraform file.
              </div>
            </div>
          }
          secondarySlot={
            <img
              src={dependencies}
              alt="A screenshot of dependencies list"
              className="mx-auto h-auto w-full"
              />
          }
        />
        <SplitSection
          primarySlot={
            <div className="lg:pr-32 xl:pr-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
                Process and import data automatically
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              With Strio, you can run jobs to provision an environment with data right after its deployment, 
              restore a dump or even import data directly from one of your database.
              </div>
            </div>
          }
          secondarySlot={
            <img
              src={datascreenshot}
              alt="A screenshot of data configuration"
              className="mx-auto h-auto w-full"
              />
          }
        />
      </section>

      <SplitSection
      reverseOrder
          primarySlot={
            <div className="lg:pl-32 xl:pl-12">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
              Keep track of your deployments
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
              Strio's dashboard keeps track of all your environments and gives you access to their metrics.  
              Easily retrieve the cost associated to these environments.         
              </div>
            </div>
          }
          secondarySlot={
            <img
              src={dashboard}
              alt="A screenshot of Strio's dashboard"
              className="mx-auto h-auto w-full"
              />
          }
        />

      <section id="features" className="bg-gray-200 py-12 shadow-inner">
        <div className="container mx-auto text-center py-12 px-10 lg:px-20">
          <h3 className="text-2xl md:text-4xl font-extrabold leading-snug lg:w-2/3 px-3 mx-auto tracking-tight text-lownight font-display">
            Integrates with all your tools
          </h3>
          <p className="text-base md:text-xl mt-12 text-gray-700 px-0 md:px-8">
            Strio supports multi-cloud and hybrid cloud configurations and integrates with your
            favorite tools, so you can continue to work with the tools that you use and love.
          </p>
          <div className="mt-8 sm:mx-3 grid grid-cols-2 lg:grid-cols-5">
            {images.tools.edges.map(tool => (
              <div key={tool.node.name} className="flex-1 p-3">
                <FeatureCard>
                  <Img
                    fixed={tool.node.childImageSharp.fixed}
                    alt={tool.node.name}
                    className="mx-auto my-2"
                  />
                </FeatureCard>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
